import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, delay, retryWhen, catchError } from 'rxjs/operators';
import { AlertErrorService } from '../service/tcdb/alert-error.service';
export const maxRetries = 0;
export const delayMs = 2000;
@Injectable({
  providedIn:'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private alertErrorService: AlertErrorService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen((error) => {
        return error.pipe(
          mergeMap((error, index) => {
            if (index < maxRetries && error.status == 500) {
              return of(error).pipe(delay(delayMs));
            }
            throw error;
          })
        )}
      ),
      catchError((error: HttpErrorResponse) => {
        this.alertErrorService.errorMessage.next(error.error);
        return throwError(error.error);
      })
    )
  }
}
