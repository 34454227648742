import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AlertSuccessComponent } from './components/alert-success/alert-success.component';
import { CandidateExportPdfComponent } from './components/candidate-export-pdf/candidate-export-pdf.component';
import { CandidateViewDetailsExportPdfComponent } from './components/candidate-view-details-export-pdf/candidate-view-details-export-pdf.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DetailsTabItemComponent } from './components/details-tab-item/details-tab-item.component';
import { DisplayTableComponent } from './components/display-table/display-table.component';
import { ExpansionPanelCheckboxesComponent } from './components/expansion-panel-checkboxes/expansion-panel-checkboxes.component';
import { ExpansionPanelChildComponent } from './components/expansion-panel/expansion-panel-child/expansion-panel-child.component';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { FilterChipComponent } from './components/filter-chip/filter-chip.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SearchResultHeaderComponent } from './components/search-result-header/search-result-header.component';
import { SearchResultItemComponent } from './components/search-result-item/search-result-item.component';
import { SectionComponent } from './components/section-control/section-control.component';
import { TestsInformationTabComponent } from './components/tests-information-tab/tests-information-tab.component';
import { ConnectionPerformanceSectionComponent } from './components/upload-utility/connection-performance-section/connection-performance-section.component';
import { DetailsSectionComponent } from './components/upload-utility/details-section/details-section.component';
import { TestInformationSectionComponent } from './components/upload-utility/test-information-section/test-information-section.component';
import { AboutAppComponent } from './pages/about/pages/about-app.component';
import { CandidateViewDetailsComponent } from './pages/candidate-view-details/candidate-view-details.component';
import { HomeAppComponent } from './pages/home/pages/home-app.component';
import { LoadComponent } from './pages/load/load.component';
import { SearchComponent } from './pages/search/search.component';
import { UploadUtilityComponent } from './pages/upload-utility/upload-utility/upload-utility.component';
import { TableeditorComponent } from './pages/tableeditor/tableeditor.component';
import { AddNewConnectionComponent } from './pages/add-new-connection/add-new-connection.component';


@NgModule({
  providers:[DatePipe],
  declarations: [
    AboutAppComponent,
    AlertSuccessComponent,
    CandidateExportPdfComponent,
    CandidateViewDetailsComponent,
    ConfirmationModalComponent,
    DisplayTableComponent,
    ExpansionPanelCheckboxesComponent,
    ExpansionPanelComponent,
    ExpansionPanelChildComponent,
    FilterChipComponent,
    HomeAppComponent,
    LoadComponent,
    PaginationComponent,
    SearchComponent,
    SearchResultItemComponent,
    SearchResultHeaderComponent,
    SectionComponent,
    LandingPageComponent,
    DetailsTabItemComponent,
    TestsInformationTabComponent,
    CandidateViewDetailsExportPdfComponent,
    UploadUtilityComponent,
    DetailsSectionComponent,
    TestInformationSectionComponent,
    ConnectionPerformanceSectionComponent,
    TableeditorComponent,
    AddNewConnectionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule
  ]
})
export class TcdbModule { }
