<div style="position:relative; right:0px">


  <div class="em-js-show-hide-target">
    <div class="em-c-toolbar" style="flex-direction: row-reverse; align-items: stretch;" [ngClass]="!isExpanded ? 'tcdb-show-content' :  ''">

      <div class="content-container" [ngClass]="!isExpanded ? 'tcdb-show-content-child' :  'tcdb-hide-content'">
        <button (click)="toggleContent()" [attr.aria-expanded]="isExpanded" style="border: none; background: none">
          <div class="em-c-btn__inner">
            <span class="em-c-btn__text">{{ isExpanded ? 'Hide Filter' : 'Show Filter' }}</span>

            <img *ngIf="isExpanded" style="width: 17px; position: relative;left: 5px;"
              src="../../../../../assets/vendor/unity/images/16/expanderUp.png" alt="Expander" />
            <img *ngIf="!isExpanded" style="width: 17px; position: relative; left: 5px; border: none;"
              src="../../../../../assets/vendor/unity/images/16/expanderDown.png" alt="Collapse" />
          </div>
        </button>
      </div>

      <div style="width:100%" *ngIf="isExpanded">
        <div class="em-l-grid em-l-grid--3up">
          <div class="em-u-min-width-20 em-l-grid__item">
            <div class="fpo-block">
              <div class="em-c-field em-c-field--inline em-c-field--small">
                <label for="" class="em-c-field__label ">Sort By</label>
                <div class="em-c-field__body">
                  <select class="em-c-select em-c-select" (change)="onSortByChange($event.target.value)">
                    <option *ngFor="let sortby of sortbylist" [value]="sortby.value">{{sortby.display}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="em-l-grid__item align-end">
            <div class="em-c-field em-c-field--inline em-c-field--small">
              <label for="" class="em-c-field__label">View</label>
              <div class="em-c-field__body">
                <select class="em-c-select em-c-select" (change)="onViewChange($event.target.value)">
                  <option *ngFor="let view of viewList" [value]="view">{{view}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="em-l-grid__item">
            <app-pagination [numPages]="numberOfPages" [currentPage]="currentPage"
              (pageClick)="pageClick($event)"></app-pagination>
          </div>
        </div>

        <div class="fpo-block" style="padding:10px;">
          <div class="em-c-field em-c-field--inline em-c-field--small">
            <app-filter-chip [filterChipItems]="filterChipItems"
              (filterChipChanged)="closeFilterFilterChip($event)"></app-filter-chip>
            <div class="em-c-field__body tcdb-search-reset">
              <button class="em-c-btn" (click)="resetFilters()" *ngIf="filterChipItems.length > 0"
                data-testid="resetfilter">
                <span class="em-c-btn__text">Reset</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>