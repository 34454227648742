import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IConnectionDescCustomFields } from 'src/app/common/models/requests/interfaces/ConnectionRequest';
import { IUnitResult, IUnitResultReqeuest as IUnitResultRequest } from 'src/app/common/models/responses/interfaces/IUnitResult';
import { UnitOfMeasureModel } from 'src/app/common/models/unitOfMeasureModel';
import { LoaderService } from 'src/app/service/tcdb/loader.service';
import { UnitOfMeasureService } from 'src/app/service/tcdb/unitofmeasure.service';
import { TabStorageService } from '../../../../../service/tcdb/tabstorage.service';
import { UploadUtilityService } from '../../../pages/upload-utility/upload-utility.service';
import { IDetailSectionControl } from '../IDetailSectionControl';
import { ConnectionDescription, DescriptonDetailControls, DropdownTypeAdditionalDetailSectionControlNames, DropDownYesNoOptions } from '../upload-utility-helper';


@Component({
  selector: 'app-details-section',
  templateUrl: './details-section.component.html',
  styleUrls: ['./details-section.component.css']
})
export class DetailsSectionComponent implements OnInit {

  connectionDescCustomFields: IConnectionDescCustomFields;
  detailsForm: FormGroup = new FormGroup({});
  dropDownOptions = [DropDownYesNoOptions.Yes, DropDownYesNoOptions.No];
  unitMeasures: IUnitResult = <any>{};
  connectionDescriptionEnums = ConnectionDescription;
  detailsSectionSpecificKey: string;
  @Input() detailsData: any;
  @Input() isDiscard: boolean=false;
  dropdownTypeAdditionalDetailSectionControlNames = DropdownTypeAdditionalDetailSectionControlNames
  constructor(private uploadUtilityService: UploadUtilityService, private unitMeasure: UnitOfMeasureService, private tabStorageService: TabStorageService,
    private loaderService: LoaderService) {
    this.detailsSectionSpecificKey = 'dsSpecificKeyToSave_' + this.tabStorageService.getTabId();
    this.uploadUtilityService.DetailsSectionData.subscribe(data => {
      const sealTypeData = data[1];
      data.forEach(row => {
        Object.entries(row).forEach(([key, value]) => {
          if (this.detailsForm.controls[key]) {
            let stringValue = String(value);

            // Check if the value is a date in the format "dd/MM/yyyy" or similar
            if (/\d{1,2}\/\d{1,2}\/\d{2,4}/.test(stringValue)) {
              const [day, month, year] = stringValue.split('/');
              const formattedDate = `${year.length === 2 ? '20' + year : year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
              this.detailsForm.controls[key].setValue(formattedDate);
            } else {
              // Handle numeric values
              if (!isNaN(Number(stringValue)) && stringValue !== null && stringValue !== '') {
                stringValue = parseFloat(stringValue).toFixed(2);
              }
              this.detailsForm.controls[key].setValue(stringValue);
            }
          }
        });
      });

      this.detailsForm.controls[this.connectionDescriptionEnums.e_SealShoulderInformation_ModifiedBy].setValue(sealTypeData.MOD_UID);
      this.detailsForm.controls[this.connectionDescriptionEnums.e_SealShoulderInformation_ChangeDate].setValue(sealTypeData.CHNG_DATE);
      this.saveDetailsSectionLocalStorage(this.detailsForm?.value);
      this.getUOM();

    });
  }
  ngOnChanges() {
    if(this.isDiscard==true){
      if (this.detailsData.length > 0) {
        Object.entries(this.detailsData[0]).forEach(([key, value]) => {
          if (this.detailsForm.controls[key]) {
            let stringValue = String(value);
  
            if (!isNaN(Number(stringValue)) && stringValue !== null && stringValue !== '') {
              stringValue = parseFloat(stringValue).toFixed(2);
            }
            this.detailsForm.controls[key].setValue(stringValue);
          }
        });
      }
      }
    }

  ngOnInit(): void {
    const constants: IDetailSectionControl[] = DescriptonDetailControls;

    constants.forEach((detailSectionControl: IDetailSectionControl) => {
      this.detailsForm.addControl(detailSectionControl.controlName, new FormControl(detailSectionControl.defaultValue));
    });

    if (!this.uploadUtilityService.isCSEUpload) {
      this.getUOMOnly();
    }
    this.unitMeasure.savedUnitOfMeasure.subscribe((changedUOM: UnitOfMeasureModel) => {
      if (changedUOM && changedUOM.unitOfMeasure !== undefined) {
        this.getUOM();
      }
    });
    this.uploadUtilityService.detailSectionLoaded.next(true);
  }
  public saveDetailsSectionLocalStorage(item: any) {
    sessionStorage.setItem(this.detailsSectionSpecificKey, JSON.stringify(item));
  }
  public getDetailsSectionLocalStorage() {
    let detailsSectionArray = JSON.parse(sessionStorage.getItem(this.detailsSectionSpecificKey));
    return detailsSectionArray;
  }

  private getUOMOnly() {
    const inputData: IUnitResultRequest = {
      unit: this.unitMeasure.getUnitOfMeasure(),
      weight: 0,
      oD: 0,
      wallThickness: 0,
      yield: 0,
      couplingBoxOD: 0,
      couplingBoxLength: 0,
      makeupLoss: 0,
      finalMin: 0,
      finalMax: 0,
      finalOptimum: 0,
      shoulderMin: 0,
      shoulderMax: 0,
      yieldMin: 0,
      tensionPipeBody: 0,
      tensionConnection: 0,
      tensionEfficiency: 0,
      compressionPipebody: 0,
      compressionConnection: 0,
      compressionEfficiency: 0,
      internalPressurePipebody: 0,
      internalPressureConnection: 0,
      internalPressureEfficiency: 0,
      externalPressurePipebody: 0,
      externalPressureConnection: 0,
      externalPressureEfficiency: 0,
    };

    const filteredInputData = Object.fromEntries(
      Object.entries(inputData).filter(([key, value]) => value !== 'N/A')
    ) as IUnitResultRequest;

    this.uploadUtilityService.getUnitOfMeasureByUnitType(filteredInputData).subscribe(data => {
      this.unitMeasures = data;
    })
  }

  private getUOM() {
    let inputData: IUnitResultRequest;
    if (this.detailsForm && this.uploadUtilityService.isCSEUpload) {
      const formControls = this.detailsForm.controls;
      const data = this.getDetailsSectionLocalStorage();
      inputData = {
        unit: this.unitMeasure.getUnitOfMeasure(),
        weight: data[this.connectionDescriptionEnums.e_NOM_PIPE_WT],
        oD: data[this.connectionDescriptionEnums.e_NOM_PIPE_OD_DIAM],
        wallThickness: data[this.connectionDescriptionEnums.e_NOM_PIPE_WALL_THCK],
        yield: data[this.connectionDescriptionEnums.e_NOM_PIPE_YLD_STRGTH],
        couplingBoxOD: data[this.connectionDescriptionEnums.e_NOM_BOX_CPL_OD],
        couplingBoxLength: data[this.connectionDescriptionEnums.e_NOM_BOX_CPL_LNG],
        makeupLoss: data[this.connectionDescriptionEnums.e_MU_LOSS_LEN],
        finalMin: data[this.connectionDescriptionEnums.e_RCMD_MIN_MU_TRQ],
        finalMax: data[this.connectionDescriptionEnums.e_RCMD_MAX_MU_TRQ],
        finalOptimum: data[this.connectionDescriptionEnums.e_RCMD_MAX_MU_TRQ],
        shoulderMin: data[this.connectionDescriptionEnums.e_RCMD_MIN_SHLDR_TRQ],
        shoulderMax: data[this.connectionDescriptionEnums.e_RCMD_MAX_SHLDR_TRQ],
        yieldMin: data[this.connectionDescriptionEnums.e_YLD_TRQ],
        tensionPipeBody: data[this.connectionDescriptionEnums.e_PIPE_TENS_RTNG],
        tensionConnection: data[this.connectionDescriptionEnums.e_CNCT_TNSN_RTNG],
        tensionEfficiency: data[this.connectionDescriptionEnums.e_NOM_TNSL_EFC_PCT],
        compressionPipebody: data[this.connectionDescriptionEnums.e_PIPE_CMPRS_RTNG],
        compressionConnection: data[this.connectionDescriptionEnums.e_CNCT_CMPRS_RTNG],
        compressionEfficiency: data[this.connectionDescriptionEnums.e_NOM_CMPRS_EFC_PCT],
        internalPressurePipebody: data[this.connectionDescriptionEnums.e_PIPE_INTRNL_PRSR_RTNG],
        internalPressureConnection: data[this.connectionDescriptionEnums.e_CNCT_INTRNL_PRSR_RTNG],
        internalPressureEfficiency: data[this.connectionDescriptionEnums.e_NOM_INTRNL_PRSR_EFC_PCT],
        externalPressurePipebody: data[this.connectionDescriptionEnums.e_PIPE_EXT_PRSR_RTNG],
        externalPressureConnection: data[this.connectionDescriptionEnums.e_CNCT_EXT_PRSR_RTNG],
        externalPressureEfficiency: data[this.connectionDescriptionEnums.e_NOM_EXT_PRSR_EFC_PCT],
      }
    }

    const filteredInputData = Object.fromEntries(
      Object.entries(inputData).filter(([key, value]) => value !== 'N/A')
    ) as IUnitResultRequest;

    this.uploadUtilityService.getUnitOfMeasureByUnitType(filteredInputData).subscribe(data => {
      this.unitMeasures = data;
      this.loaderService.isLoading.next(true);
      const formControls = this.detailsForm.controls;

      const patchValues = {
        [this.connectionDescriptionEnums.e_NOM_PIPE_WT]: data.weight,
        [this.connectionDescriptionEnums.e_NOM_PIPE_OD_DIAM]: data.od,
        [this.connectionDescriptionEnums.e_NOM_PIPE_WALL_THCK]: data.wallThickness,
        [this.connectionDescriptionEnums.e_NOM_PIPE_YLD_STRGTH]: data.yield,
        [this.connectionDescriptionEnums.e_NOM_BOX_CPL_OD]: data.couplingBoxOD,
        [this.connectionDescriptionEnums.e_NOM_BOX_CPL_LNG]: data.couplingBoxLength,
        [this.connectionDescriptionEnums.e_MU_LOSS_LEN]: data.makeupLoss,
        [this.connectionDescriptionEnums.e_RCMD_MIN_MU_TRQ]: data.finalMin,
        [this.connectionDescriptionEnums.e_RCMD_MAX_MU_TRQ]: data.finalMax,
        [this.connectionDescriptionEnums.e_RCMD_MAX_MU_TRQ]: data.finalOptimum,
        [this.connectionDescriptionEnums.e_RCMD_MIN_SHLDR_TRQ]: data.shoulderMin,
        [this.connectionDescriptionEnums.e_RCMD_MAX_SHLDR_TRQ]: data.shoulderMax,
        [this.connectionDescriptionEnums.e_YLD_TRQ]: data.yieldMin,
        [this.connectionDescriptionEnums.e_PIPE_TENS_RTNG]: data.tensionPipeBody,
        [this.connectionDescriptionEnums.e_CNCT_TNSN_RTNG]: data.tensionConnection,
        //[this.connectionDescriptionEnums.e_NOM_TNSL_EFC_PCT]: data.tensionEfficiency,
        [this.connectionDescriptionEnums.e_PIPE_CMPRS_RTNG]: data.compressionPipebody,
        [this.connectionDescriptionEnums.e_CNCT_CMPRS_RTNG]: data.compressionConnection,
        // [this.connectionDescriptionEnums.e_NOM_CMPRS_EFC_PCT]: data.compressionEfficiency,
        [this.connectionDescriptionEnums.e_PIPE_INTRNL_PRSR_RTNG]: data.internalPressurePipebody,
        [this.connectionDescriptionEnums.e_CNCT_INTRNL_PRSR_RTNG]: data.internalPressureConnection,
        // [this.connectionDescriptionEnums.e_NOM_INTRNL_PRSR_EFC_PCT]: data.internalPressureEfficiency,
        [this.connectionDescriptionEnums.e_PIPE_EXT_PRSR_RTNG]: data.externalPressurePipebody,
        [this.connectionDescriptionEnums.e_CNCT_EXT_PRSR_RTNG]: data.externalPressureConnection,
        // [this.connectionDescriptionEnums.e_NOM_EXT_PRSR_EFC_PCT]: data.externalPressureEfficiency,
      };

      Object.keys(patchValues).forEach(key => {
        if (formControls[key]) {
          formControls[key].setValue(patchValues[key]);
        }
      });
      this.loaderService.isLoading.next(false);
    });
  }

  get getConnectionDetailFormData() {
    return this.detailsForm.value;
  }

  updateCustomLogicFields(connectionDescCustomFields: IConnectionDescCustomFields): void {
    this.connectionDescCustomFields = connectionDescCustomFields;
  }
}
