import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICandidateConnectionResult } from '../../../../common/models/responses/interfaces/iCandidateConnectionResult.interface';
import { CandidateConnectionResult } from '../../../../common/models/responses/candidateConnectionResult';
import { ISearchCriteriaRequest } from '../../../../common/models/requests/interfaces/isearchcriteriarequest';
import { SearchCriteriaRequest } from '../../../../common/models/requests/searchcriteriarequest';
import { Constants } from '../../../../common/constants/constant';
import { SearchCriteriaService } from 'src/app/service/tcdb/searchcriteria.service';

@Component({
  selector: 'app-search-result-item',
  templateUrl: './search-result-item.component.html',
  styleUrls: ['./search-result-item.component.css']
})
export class SearchResultItemComponent implements OnInit {
  @Input() candidateConnectionResultsPagination;
  @Input() searchCriteriaRequest: ISearchCriteriaRequest = new SearchCriteriaRequest();
  @Output() viewDetails = new EventEmitter<string>();
  isApplySearchExpanded: boolean;

  constructor(private searchCriteriaService: SearchCriteriaService) {
  }
  ngOnInit(): void {
    this.searchCriteriaService.isApplyFilterExpanded.subscribe(isApplySearchExpanded => {
      this.isApplySearchExpanded = isApplySearchExpanded;
    });
  }

  loadMaterialTitle() {
    let materialTitle = '';
    if (this.searchCriteriaRequest.isCarbonSteel) {
      materialTitle += ' ' + Constants.carbonSteel;
    }
    if (this.searchCriteriaRequest.isCRA) {
      materialTitle += ' ' + Constants.CRA;
    }
    return materialTitle;

  }

  loadConnectionTypeTitle() {
    let connectionTypeTitle = '';
    if (this.searchCriteriaRequest.isThreadedAndCoupled) {
      connectionTypeTitle += ' ' + Constants.ThreadedAndCoupled;
    }
    if (this.searchCriteriaRequest.isIntegralJoint) {
      connectionTypeTitle += ' ' + Constants.IntegralJoint;
    }
    if (this.searchCriteriaRequest.isSemiFlush) {
      connectionTypeTitle += ' ' + Constants.SemiFlushJoint;
    }
    if (this.searchCriteriaRequest.isFlushJoint) {
      connectionTypeTitle += ' ' + Constants.FlushJoint;
    }

    return connectionTypeTitle;

  }

  onViewDetails(id) {
    this.viewDetails.emit(id);
  }

}
