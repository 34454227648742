import { Component, OnInit } from '@angular/core';
import { ITableRow } from '../../../../common/models/components/iTableRow';
import { ITableHeader } from '../../../../common/models/components/iTableHeader';
import { vTableMap } from '../../../../common/models/vTableMap.model';

@Component({
  selector: 'app-tableeditor',
  templateUrl: './tableeditor.component.html',
  styleUrls: ['./tableeditor.component.css']
})

export class TableeditorComponent implements OnInit {
  parameterlist: vTableMap[];
  tableHeader: ITableHeader[] = [];
  tableData: ITableRow[] = [];
  useAddRow = true;
  showTable = false;
  constructor() { }

  ngOnInit(): void {
    this.parameterlist = [
      {
        key: "CNCT_GEOM_TYPE_NM",
        value: "CNCT_GEOM_TYPE_VTBL"
      },
      {
        key: "LINK_TYPE_NM",
        value: "CNCT_LINK_TYPE_VTBL"
      }
    ];

    this.tableHeader.push({ name: `Value`, isNumeric: false, errorMessage: '' });
    this.tableHeader.push({ name: `CHNG_DATE`, isNumeric: false, errorMessage: '' });
    this.tableHeader.push({ name: `MOD_UID`, isNumeric: false, errorMessage: '' });

    //Dummy data in the table
    let tableRow: ITableRow = {
      editable: true, tdColumn: [{ editable: false, errorMessage: '', headerName: 'Value', value: "Dummy1" }, { editable: false, errorMessage: '', headerName: 'CHNG_DATE', value: "18/09/2024" }, { editable: false, errorMessage: '', headerName: 'MOD_UID', value: "Test User 1" }], colspan: 1
    };
    this.tableData.push(tableRow);

    let tableRow1: ITableRow = {
      editable: true, tdColumn: [{ editable: false, errorMessage: '', headerName: 'Value', value: "Dummy2" }, { editable: false, errorMessage: '', headerName: 'CHNG_DATE', value: "18/09/2024" }, { editable: false, errorMessage: '', headerName: 'MOD_UID', value: "Test User 2" }], colspan: 1
    };
    this.tableData.push(tableRow1);
  }

  deleteTableData(index: number) {
    this.tableData.splice(index, 1);
  }

  changedata() {
    //updating the table column name on change of dropdown value.
    this.tableHeader[0].name = "CNCT_GEOM_TYPE_NM";
    this.showTable = true;
  }

}
