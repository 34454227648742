import { EndPoints } from './../app-config/endpoints';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IFilterResponse } from '../../common/models/responses/interfaces/iFilterResponse.interface';
import { Observable } from 'rxjs';
import { ISizeFilterResponse } from '../../common/models/responses/interfaces/iSizeFilterResponse';
import { IYieldFilterResponse } from '../../common/models/responses/interfaces/iYieldFilterResponse';
import { ISortByResponse } from '../../common/models/responses/interfaces/iSortByResponse';
import { IManufacturerFilterResponse } from '../../common/models/responses/interfaces/iManufacturerFilterResponse';
import { UnitOfMeasureService } from './unitofmeasure.service';

@Injectable({ providedIn: 'root' })
export class InputDataService {

  public headers = { 'content-type': 'application/json' };
  public _unitOfMeasureService: UnitOfMeasureService;

  constructor(private http: HttpClient, unitOfMeasure: UnitOfMeasureService) {
    this._unitOfMeasureService = unitOfMeasure;
  }

  async getThreadCompoundTypes(): Promise<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/inputdata/availablethreadcompoundtypes`;
    return await this.http
      .get<IFilterResponse>(endpoint).toPromise();
  }

  async getConnectionUse(): Promise<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/inputdata/getconnectionuse`;
    return await  this.http
      .get<IFilterResponse>(endpoint).toPromise();
  }

  async getConnectionTypes(): Promise<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/inputdata/getconnectiontype`;
    return await this.http
      .get<IFilterResponse>(endpoint).toPromise();
  }

  async getAdditonalFilter(): Promise<any> {
    let endpoint = `${EndPoints.API_BASE_URL}/inputdata/AdditonalSearchParams`;
    return await this.http
      .get<any>(endpoint).toPromise();
  }

  async getMaterialTypes(): Promise<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/inputdata/getmaterialtype`;
    return await this.http
      .get<IFilterResponse>(endpoint).toPromise();
  }

  async getOuterDiameter(): Promise<ISizeFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/inputdata/distinctouterdiameters/${this._unitOfMeasureService.getUnitOfMeasure()}`;
    return await this.http
      .get<ISizeFilterResponse>(endpoint).toPromise();
  }

  async getAvailableYieldTypes(): Promise<IYieldFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/inputdata/availableyieldtypes/${this._unitOfMeasureService.getUnitOfMeasure()}`;
    return await this.http.get<IYieldFilterResponse>(endpoint).toPromise();
  }

  async getEvaluation(): Promise<IFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/inputdata/getevaluation`;
    return await this.http.get<IFilterResponse>(endpoint).toPromise();
  }

  async getSortBy(): Promise<ISortByResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/inputdata/getsortby`;
    return await this.http.get<ISortByResponse>(endpoint).toPromise();
  }
  async getManufacturerAndConnection(): Promise<IManufacturerFilterResponse> {
    let endpoint = `${EndPoints.API_BASE_URL}/inputdata/distinctmanufacturerandconnection`;
    return await this.http.get<IManufacturerFilterResponse>(endpoint).toPromise();
  }


}
