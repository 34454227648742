<div class="connection-component">
  <div class="header">
    <h2>Add New Connection</h2>
    <button class="close-button" (click)="close()">Close</button>
  </div>

  <div class="border__body">
    <div class="header">
      <h2> </h2>
      <button class="download-button" (click)="downloadTemplate()" formtarget="_self" type="submit">Download blank CES template</button>
    </div>
    <div>
      <label class=".select_radio">
        <input type="radio" name="connectionMethod" value="upload" [(ngModel)]="connectionMethod">
        Upload from a CES file
      </label>
      <label>
        <input type="radio" name="connectionMethod" value="manual" [(ngModel)]="connectionMethod">
        Manual Entry
      </label>
      <div class="header" style=" display: flex; flex-direction: row-reverse;height: 0px;">
        <button class="em-c-btn em-c-btn--primary em-c-btn--small em-js-btn-selectable continue-button"
                (click)="onContinue()">
          Continue
        </button>
      </div>
    </div>

  </div>

</div>

<!--Section Modal-->
<div *ngIf="uploadModalAddConnection" class="em-js" id="div_modal_start">
  <div class="em-c-modal em-js-modal" id="modal">
    <div class="em-c-modal__window em-js-modal-window">
      <div class="em-c-modal__header header-color">
        <h3 class="em-c-modal__title">Upload Connection Data Sheet</h3>
        <button (click)="closeUploadModalAddConnection()" class="model_close-button">Close</button>
      </div>
      <div class="border_header_bottom">
      </div>
      <div class="em-c-field__body">
        <div class="em-c-field__list em-js-field-list">
          <img class="em-c-field__item"
               src="/assets/images/uploadImage.ico" />
        </div>
        <input type="file" id="file" name="file" class="em-c-file-upload" aria-labelledby="fileUploadLabel"
               aria-describedby="fileUploadDesc" aria-required="false" accept=".xlsx" (change)="onFileSelected($event)">
        <ul class="em-c-field__list em-js-field-list">
          <li class="em-c-field__item">Drag files here</li>
          <li class="em-c-field__item em-c-field__item--small">Or click to choose file</li>
        </ul>
      </div>
      <p *ngIf="showError" class="error-message">Kindly select valid CES file.</p>
      <p *ngIf="fileName" class="file-name">Selected file: {{ fileName }}</p>
      <p class="p-font">Click the box above to browse your system for the desired file.</p>
      <div class="border__body">
        <button class="em-c-btn em-c-btn--primary  em-js-btn-selectable" (click)="uploadFile()">Upload</button>
        <button style="left: 25px;" class="em-c-btn em-c-btn--secondary em-js-modal-cancel-trigger cancel-button"
                (click)="closeUploadModalAddConnection()">
          Cancel
        </button>
      </div>
    </div>
  </div>

</div>

<!--End Section Modal-->
