import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IExpandableMenuItem } from '../../../../common/models/components/iExpandableMenuItem';
import { IExportToExcel, IExportToExcelRow } from '../../../../common/models/components/iExportToExcel';
import { LoadComponent } from '../../../tcdb/pages/load/load.component';
import { Constants } from '../../../../common/constants/constant';
import { ExportToExcelComponent } from '../../../tcdb/components/export-to-excel/export-to-excel.component';
import { UnitOfMeasureService } from '../../../../service/tcdb/unitofmeasure.service';
import { LoadCaseService } from '../../../../service/tcdb/loadcaseservice';

@Component({
  selector: 'app-expandable-menu',
  templateUrl: './expandable-menu.component.html',
  styleUrls: ['./expandable-menu.component.css']
})
export class ExpandableMenuComponent {
  isCollapsed: boolean = false;
  @Input() childrenList: IExpandableMenuItem[];
  @Input() title = '';
  @Output() childAction = new EventEmitter<string>();
  @Input() checkedChild = '';
  fileName = 'Loads.xlsx';
  pressureAcr: string = '';
  tensionAcr: string = '';
  @ViewChild(LoadComponent) loadComponent: LoadComponent;
  @ViewChild(ExportToExcelComponent) exportToExcelComponent: ExportToExcelComponent;
  excelExport: IExportToExcel[] = [];
  isBlankExcelTemplate = true;
  constructor(private _loadCaseService: LoadCaseService) {
    this.excelExport = this._loadCaseService.generateBlankTemplateExportToExcel();
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  onChildClick(value) {
    localStorage.setItem('AddNewConnection', ''); 
    if (value == Constants.loadExportTemplate) {
      this.exportLoadToExcel();
    }
    else {
      this.childAction.emit(value);
      this.isCollapsed = false;
    }
  }

  isCheckedChild(value: string) {
    if (this.checkedChild == null)
      return false;
    return value.toLowerCase() == this.checkedChild.toLowerCase();
  }

  exportLoadToExcel() {
    this.exportToExcelComponent.exportToExcel();
  }
}
