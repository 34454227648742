<app-candidate-view-details *ngIf="isToShowViewDetails" [candidateConnection]="candidateConnectionResultViewDetails" (backEvent)="onBackClick()"></app-candidate-view-details>

<div *ngIf="!isToShowViewDetails">
  <app-alert-error></app-alert-error>
  <div class="em-l em-l--two-column-wide">
    <div style="padding-left: 24px; padding-right: 12px; padding-bottom: 24px;border-right: solid;border-right-width: thin;border-right-color: #e5e5e5;">
      <div class="scrollbar">
        <div class="fpo-block">
          <app-expansion-panel *ngFor="let expansionFilter of sortedExpansionFilters()" [expansionFilter]='expansionFilter' (checkBoxEvent)="updateExpansionFilter($event)"></app-expansion-panel>
          <input type="text" id="txtCESReportId" class="em-c-input em-js-input" placeholder="Document Number" value="" name="cesReportId" [(ngModel)]="cesReportId" style=" width: 95%; padding-bottom:10px"/>
        </div>
      </div>
      <div style="padding-top: 10px; padding-bottom: 10px; padding-left: 240px; height: 30px; width:50px; display: flex; flex-direction: row-reverse;"><button (click)="onSearch(); onSearchSet()" class="em-c-btn em-c-btn--primary em-js-btn-selectable margin-auto" data-testid="apply-button"><span class="em-c-btn__text">Apply</span></button></div>
    </div>
    <div class="em-l__main">
      <!-- end em-c-field -->
      <div *ngIf="filterChipItems.length<1 && !addConnection"><app-landing-page [pageTitle]="searchEmptyTitle" [pageMessage]="searchEmptyMessage" [paddingValue]="paddingVal" ></app-landing-page> </div>
      <div *ngIf="filterChipItems.length>=1 && !addConnection" class="fpo-block fpo-block--dark" style="padding-left: 12px; padding-right: 12px;">
        <div style="width:100%">
          <div class="em-l-grid em-l-grid--2up">
            <div class="em-l-grid__item">
              <div class="fpo-block"> <b>{{candidateConnectionResults.length}} Results</b></div>
            </div>
            <div class="em-l-grid__item align-end">
              <a href="javascript:void(0);">
                <svg class="em-c-icon em-c-icon--medium" (click)="openPDF()">
                  <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="../../../assets/vendor/unity/images/24/em-icons.svg#file-pdf-2"></use>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <app-search-result-header [sortbylist]="sortByResponse.data" (sortbyEvent)="onSortByChange($event)" [totalResults]="candidateConnectionResults.length" [searchCriteriaRequest]="searchCriteriaRequest" [filterChipItems]="filterChipItems" (closeFilter)="closeFilter($event)" (resetFiltersOutput)="resetFilters()" (pageClickEvent)="pageClick($event)" [numberOfPages]="numberOfPages" (viewChangeEvent)="onViewChange($event)" [currentPage]="pageSelected"></app-search-result-header>
        <app-search-result-item [candidateConnectionResultsPagination]="candidateConnectionResultsPagination" [searchCriteriaRequest]="searchCriteriaRequestTitleResult" (viewDetails)="viewDetails($event)"></app-search-result-item>
      </div>
      <app-add-new-connection *ngIf="addConnection"></app-add-new-connection>
      <div class="noresult-heading" *ngIf="candidateConnectionResults.length < 1 && filterChipItems.length > 0 && !addConnection"> No results found. Try altering or reducing your applied filters.</div>
    </div>
  </div>
</div>

<app-candidate-export-pdf [selectionCriteria]="filterChipItemsToPDF" [candidateConnectionResultsWithEnvelope]="candidateConnectionResultsWithEnvelope"></app-candidate-export-pdf>


