import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CoreMainComponent } from './modules/main/pages/core-main/core-main.component';
import { UnauthorizedComponent } from './modules/main/pages/unauthorized/unauthorized.component';
import { AboutAppComponent } from './modules/tcdb/pages/about/pages/about-app.component';
import { CandidateViewDetailsComponent } from './modules/tcdb/pages/candidate-view-details/candidate-view-details.component';
import { HomeAppComponent } from './modules/tcdb/pages/home/pages/home-app.component';
import { LoadComponent } from './modules/tcdb/pages/load/load.component';
import { SearchComponent } from './modules/tcdb/pages/search/search.component';
import { UploadUtilityComponent } from './modules/tcdb/pages/upload-utility/upload-utility/upload-utility.component';
import { TableeditorComponent } from './modules/tcdb/pages/tableeditor/tableeditor.component';

const routes: Routes = [
  {
    path: '',
    component: CoreMainComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        redirectTo: 'search',
        pathMatch: 'full',
        canActivate: [MsalGuard]
      },
      {
        path: 'home',
        component: HomeAppComponent,
        canActivate: [MsalGuard]
      },
      {
        path: 'about',
        component: AboutAppComponent,
        canActivate: [MsalGuard]
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent
      },
      {
        path: 'search',
        component: SearchComponent,
        canActivate: [MsalGuard]
      },
      {
        path: 'load',
        component: LoadComponent,
        canActivate: [MsalGuard]
      },
      {
        path: 'upload-utility/:isUploadUtility',
        component: CandidateViewDetailsComponent,
        canActivate: [MsalGuard]
      },
      {
        path: 'viewdetails/:id',
        component: CandidateViewDetailsComponent,
        canActivate: [MsalGuard]
      },
      {
        path: 'table-editor',
        component: TableeditorComponent,
        canActivate: [MsalGuard]
      },
      {
        path: 'addNewConnection',
        component: SearchComponent,
        canActivate: [MsalGuard]
      }
    ]
  }
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled'
};


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, routerOptions)
  ]
})
export class RouteModule { }
