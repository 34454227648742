<div class="selfcontainer">
  <div class="titleLabel">
    VTable Editor
  </div>
  <div class="em-c-field" style="margin-top:20px;">
    VTable
    <select class="selectStyle" (change)="changedata()">
      <option selected disabled>Choose VTable Name</option>
      <option *ngFor="let item of parameterlist" [value]="item.key">
        {{item.value}}
      </option>
    </select>
  </div>
  <div class="em-c-field" *ngIf="showTable">
    <div class="em-c-field__body">
      <div class="scrollbar">
        <div class="fpo-block">
          <app-display-table [tableHeaderList]="tableHeader" [tableRowList]="tableData" [addRow]="useAddRow" (deleteRowEvent)="deleteTableData($event)" [showcopyfrom]="false"></app-display-table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="tcdb-save-discard-section">
  <div class="tcdb-save tcdb-cursor-pointer">Save</div>
  <div class="tcdb-discard tcdb-cursor-pointer">Discard</div>
</div>
