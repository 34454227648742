import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewBy } from '../../../../common/Enums/viewBy';
import { IFilterChipItem } from '../../../../common/models/components/iFilterChipItem';
import { ISearchCriteriaRequest } from '../../../../common/models/requests/interfaces/isearchcriteriarequest';
import { SearchCriteriaRequest } from '../../../../common/models/requests/searchcriteriarequest';
import { SearchCriteriaService } from 'src/app/service/tcdb/searchcriteria.service';


@Component({
  selector: 'app-search-result-header',
  templateUrl: './search-result-header.component.html',
  styleUrls: ['./search-result-header.component.css']
})
export class SearchResultHeaderComponent {
  @Input() sortbylist = [];
  @Output() sortbyEvent = new EventEmitter<string>();
  @Input() totalResults = 0;
  @Input() searchCriteriaRequest: ISearchCriteriaRequest = new SearchCriteriaRequest();
  @Input() numberOfPages = 0;
  @Input() filterChipItems: IFilterChipItem[] = [];
  @Output() closeFilter = new EventEmitter<IFilterChipItem>();
  @Output() resetFiltersOutput = new EventEmitter();
  @Output() pageClickEvent = new EventEmitter<number>();
  @Output() viewChangeEvent = new EventEmitter<string>();
  @Input() currentPage = 1;

  viewList = [];
  isExpanded = false

  constructor(private searchCriteriaService: SearchCriteriaService) {
    this.viewList = Object.values(ViewBy);
  }
  toggleContent() {
    this.isExpanded = !this.isExpanded;
    this.searchCriteriaService.isApplyFilterExpanded.next(this.isExpanded);
  }

  onSortByChange(value) {
    this.sortbyEvent.emit(value);
  }

  closeFilterFilterChip(filterChipItem: IFilterChipItem) {
    this.closeFilter.emit(filterChipItem);
  }

  resetFilters() {
    this.resetFiltersOutput.emit();
  }
  pageClick(index) {
    this.pageClickEvent.emit(index);
  }
  onViewChange(value) {
    this.viewChangeEvent.emit(value);
  }
}
