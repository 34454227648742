import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertErrorComponent } from '../tcdb/components/alert-error/alert-error.component';
import { ExportToExcelComponent } from '../tcdb/components/export-to-excel/export-to-excel.component';
import { ResuabledropdownComponent } from './components/resuabledropdown/resuabledropdown.component';
import { SaveDiscardComponent } from './components/save-discard/save-discard.component';
import { NumericInputDirective } from './numeric-input.directive';

@NgModule({
  declarations: [AlertErrorComponent, ExportToExcelComponent, NumericInputDirective, ResuabledropdownComponent, SaveDiscardComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [AlertErrorComponent, ExportToExcelComponent, NumericInputDirective, ResuabledropdownComponent, SaveDiscardComponent]
})
export class SharedModule { }
