import { Component, OnInit } from '@angular/core';
import { ITableHeader } from 'src/app/common/models/components/iTableHeader';
import { IColumn, ITableRow } from 'src/app/common/models/components/iTableRow';
import { COMMENTS, MATERIAL_GRADE, SPECIMEN_TYPE, TEST_DATE, TEST_FACILITY, TEST_PROCEDURE, TEST_TYPE, TestInformation_Comments, TestInformation_Date, TestInformation_Facility, TestInformation_MaterialGrade, TestInformation_Procedure, TestInformation_Specimen, TestInformation_Test } from '../upload-utility-helper';
import { UploadUtilityService } from '../../../pages/upload-utility/upload-utility.service';
import { ITestInformationDTO as ITestInformationDTO } from 'src/app/common/models/requests/interfaces/ConnectionRequest';
@Component({
  selector: 'app-test-information-section',
  templateUrl: './test-information-section.component.html',
  styleUrls: ['./test-information-section.component.css']
})
export class TestInformationSectionComponent implements OnInit {

  testInformationHeader: ITableHeader[] = [];
  testInformationColumsData: ITableRow[] = [];
  useAddRow = true;
  isToEdit = false;
  testInfoSpecificKey: string;
  constructor(private utilityService: UploadUtilityService) { }
  ngOnInit(): void {
    this.testInformationHeader.push({ name: TestInformation_Procedure, isNumeric: false, errorMessage: '', headerPropertyName: TEST_PROCEDURE });
    this.testInformationHeader.push({ name: TestInformation_Specimen, isNumeric: false, errorMessage: '', headerPropertyName: SPECIMEN_TYPE });
    this.testInformationHeader.push({ name: TestInformation_MaterialGrade, isNumeric: false, errorMessage: '', headerPropertyName: MATERIAL_GRADE });
    this.testInformationHeader.push({ name: TestInformation_Test, isNumeric: false, errorMessage: '', headerPropertyName: TEST_TYPE });
    this.testInformationHeader.push({ name: TestInformation_Date, isNumeric: false, errorMessage: '', isDate: true, headerPropertyName: TEST_DATE });
    this.testInformationHeader.push({ name: TestInformation_Facility, isNumeric: false, errorMessage: '', headerPropertyName: TEST_FACILITY });
    this.testInformationHeader.push({ name: TestInformation_Comments, isNumeric: false, errorMessage: '', headerPropertyName: COMMENTS });
  }
  public saveTestInfoLocalStorage() {
    sessionStorage.setItem(this.testInfoSpecificKey, JSON.stringify(this.testInformationColumsData));
  }
  public getTestInfoLocalStorage() {
    let testSectionArray = JSON.parse(sessionStorage.getItem(this.testInfoSpecificKey));
    return testSectionArray;
  }

  deleteRow(index: number) {
    this.testInformationColumsData.splice(index, 1);
  }

  clipboardCopied(clipboardCopiedData: string[]) {
    if (clipboardCopiedData && clipboardCopiedData.length > 0) {
      clipboardCopiedData.forEach(rowData => {
        if (rowData !== '') {
          let columnValues = rowData.split("\t");
          let tension: IColumn = {
            editable: false, headerName: TestInformation_Procedure, value: columnValues[0], errorMessage: '',
            headerPropertyName: TEST_PROCEDURE
          };
          let specimen: IColumn = {
            editable: false, headerName: TestInformation_Specimen, value: columnValues[1], errorMessage: '',
            headerPropertyName: SPECIMEN_TYPE
          };
          let materialGrade: IColumn = {
            editable: false, headerName: TestInformation_MaterialGrade, value: columnValues[2], errorMessage: '',
            headerPropertyName: MATERIAL_GRADE
          };
          let test: IColumn = {
            editable: false, headerName: TestInformation_Test, value: columnValues[3], errorMessage: '',
            headerPropertyName: TEST_TYPE
          };
          let date: IColumn = {
            editable: false, headerName: TestInformation_Date, value: columnValues[4], errorMessage: '',
            headerPropertyName: TEST_DATE
          };
          let facility: IColumn = {
            editable: false, headerName: TestInformation_Facility, value: columnValues[5], errorMessage: '',
            headerPropertyName: TEST_FACILITY
          };
          let comments: IColumn = {
            editable: false, headerName: TestInformation_Comments, value: columnValues[6], errorMessage: '',
            headerPropertyName: COMMENTS
          };
          let tableRow: ITableRow = { tdColumn: [tension, specimen, materialGrade, test, date, facility, comments], editable: true, colspan: 1 };
          this.testInformationColumsData.push(tableRow);
        }
      });
    }
  }

  get getTestInforamationData(): ITestInformationDTO[] {
    return this.testInformationColumsData.map(item => {
      let obj = {};
      item.tdColumn.forEach(col => {
        obj[col.headerPropertyName] = col.value;
      });
      return obj;
    }) as ITestInformationDTO[];
  }

  rowUpdated() {
    this.utilityService.testInformationSectionRowUpdated.next(true);
  }

  discard() {
    this.testInformationColumsData = [];
  }

}
