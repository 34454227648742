import { Component, OnInit, Input } from '@angular/core';
import { ITableHeader } from 'src/app/common/models/components/iTableHeader';
import { IColumn, ITableRow } from 'src/app/common/models/components/iTableRow';
import { TabStorageService } from '../../../../../service/tcdb/tabstorage.service';
import { UploadUtilityService } from '../../../pages/upload-utility/upload-utility.service';
import { AXIAL_LOAD, CHNG_DATE, CNCT_ID, ConnectionPerformacneTable, ConnectionPerformance_AxialLoads, ConnectionPerformance_AxialLoads_Column, ConnectionPerformance_ChangeDate, ConnectionPerformance_ChangeDate_Column, ConnectionPerformance_EnvlpType, ConnectionPerformance_EnvlpType_Column, ConnectionPerformance_ModUid, ConnectionPerformance_ModUid_Column, ConnectionPerformance_NetPrsr, ConnectionPerformance_NetPrsr_Column, ConnectionPerformance_PlotSeqnbr, ConnectionPerformance_PlotSeqnbr_Column, ENVLP_TYPE_CD, MOD_UID, NET_PRSR, PLOT_SEQ_NBR } from '../upload-utility-helper';
import { IConnectionPerformanceDTO } from 'src/app/common/models/requests/interfaces/ConnectionRequest';

@Component({
  selector: 'app-connection-performance-section',
  templateUrl: './connection-performance-section.component.html',
  styleUrls: ['./connection-performance-section.component.css']
})
export class ConnectionPerformanceSectionComponent implements OnInit {

  rowUpdated() {
    this.utilityService.connectionPerformanceSectionRowUpdated.next(true);
  }
  connectionInformationHeader: ITableHeader[] = [
    {
      name: ConnectionPerformance_EnvlpType, isNumeric: false, errorMessage: ''
      , headerPropertyName: ENVLP_TYPE_CD
    },
    { name: ConnectionPerformance_NetPrsr, isNumeric: false, errorMessage: '', headerPropertyName: NET_PRSR },
    { name: ConnectionPerformance_AxialLoads, isNumeric: false, errorMessage: '', headerPropertyName: AXIAL_LOAD },
    { name: ConnectionPerformance_PlotSeqnbr, isNumeric: false, errorMessage: '', headerPropertyName: PLOT_SEQ_NBR },
    { name: ConnectionPerformance_ModUid, isNumeric: false, errorMessage: '', headerPropertyName: MOD_UID },
    { name: ConnectionPerformance_ChangeDate, isNumeric: false, errorMessage: '', isDate: true, headerPropertyName: CHNG_DATE }
  ];

  connectionInformationColumnsData: ITableRow[] = [];
  useAddRow = true;
  isToEdit = false;
  connectionPerformananceSpecificKey: string;
  constructor(private utilityService: UploadUtilityService, private tabStorageService: TabStorageService) {
    this.connectionPerformananceSpecificKey = 'cpSpecificKeyToSave_' + this.tabStorageService.getTabId();
  }

  ngOnInit(): void {
    this.utilityService.connectionPerformanceSectionData.subscribe(data => {
      if (!data) {
        return;
      }
      const headers = [
        CNCT_ID,
        ConnectionPerformance_EnvlpType_Column,
        ConnectionPerformance_NetPrsr_Column,
        ConnectionPerformance_AxialLoads_Column,
        ConnectionPerformance_PlotSeqnbr_Column,
        ConnectionPerformance_ModUid_Column,
        ConnectionPerformance_ChangeDate_Column
      ]

      const excludeHeaders = [CNCT_ID];

      const filteredHeaders = headers.filter(header => !excludeHeaders.includes(header));

      data.forEach(row => {
        let tableRow: ITableRow = {
          tdColumn: filteredHeaders.map(header => {
            let value = row[header];
            if (header === ConnectionPerformance_AxialLoads_Column) {
              let numericValue = parseFloat(value);
              value = numericValue.toFixed(2);
            }
            return {
              editable: false,
              headerName: ConnectionPerformacneTable[header],
              value: value,
              errorMessage: '',
              headerPropertyName: header
            };
          }),
          editable: true,
          colspan: 1
        };

        this.connectionInformationColumnsData.push(tableRow);
      });
      this.saveCPdataLocalStorage(this.connectionInformationColumnsData);
    });
    this.utilityService.connectionPerformanceLoaded.next(true);
  }
  public saveCPdataLocalStorage(item: any) {
    sessionStorage.setItem(this.connectionPerformananceSpecificKey, JSON.stringify(this.connectionInformationColumnsData));
  }
  public getCPdataLocalStorage() {
    let conPerArray = JSON.parse(sessionStorage.getItem(this.connectionPerformananceSpecificKey));
    return conPerArray;
  }
  deleteRow(index: number) {
    this.connectionInformationColumnsData.splice(index, 1);
  }

  clipboardCopied(clipboardCopiedData: string[]) {
    if (clipboardCopiedData && clipboardCopiedData.length > 0) {
      clipboardCopiedData.forEach(rowData => {
        if (rowData !== '') {
          let columnValues = rowData.split("\t");
          let tension: IColumn = {
            editable: false, headerName: ConnectionPerformance_EnvlpType, value: columnValues[0], errorMessage: '',
            headerPropertyName: ENVLP_TYPE_CD
          };
          let specimen: IColumn = {
            editable: false, headerName: ConnectionPerformance_NetPrsr, value: columnValues[1], errorMessage: '',
            headerPropertyName: NET_PRSR
          };
          let materialGrade: IColumn = {
            editable: false, headerName: ConnectionPerformance_AxialLoads, value: columnValues[2], errorMessage: '',
            headerPropertyName: AXIAL_LOAD
          };
          let test: IColumn = {
            editable: false, headerName: ConnectionPerformance_PlotSeqnbr, value: columnValues[3], errorMessage: '',
            headerPropertyName: PLOT_SEQ_NBR
          };
          let date: IColumn = {
            editable: false, headerName: ConnectionPerformance_ModUid, value: columnValues[4], errorMessage: '',
            headerPropertyName: MOD_UID
          };
          let facility: IColumn = {
            editable: false, headerName: ConnectionPerformance_ChangeDate, value: columnValues[5], errorMessage: '',
            headerPropertyName: CHNG_DATE
          };
          let tableRow: ITableRow = { tdColumn: [tension, specimen, materialGrade, test, date, facility], editable: true, colspan: 1 };
          this.connectionInformationColumnsData.push(tableRow);
        }
      });
    }
  }

  get getConnectionPerformanceData(): IConnectionPerformanceDTO[] {
    return this.connectionInformationColumnsData.map(item => {
      let obj = {};
      item.tdColumn.forEach(col => {
        obj[col.headerPropertyName] = col.value;
      });
      return obj;
    }) as IConnectionPerformanceDTO[];
  }

  discard() {
    const connectionPerformananceData = JSON.parse(sessionStorage.getItem(this.connectionPerformananceSpecificKey));
    if (connectionPerformananceData.length > 0) {
      this.connectionInformationColumnsData = connectionPerformananceData;
    };
  }


}
